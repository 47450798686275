import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import OneCol from "../components/strapi/structure/OneCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import Promises from "../components/strapi/our-promise/Promises";
import Outropiggy from "../components/strapi/structure/Outropiggy";

export default function OurPromise({ data }) {
  return (
    <Layout>
      <SEO title="Our Promise" />

      {data.allStrapiOurPromise.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.op_mh.mh_title}
            mhsubtitle={node.op_mh.mh_subtitle}
            mhbackground={node.op_mh.mh_bg.publicURL}
          />
          <OneCol
            title={node.op_intro.onecol_title}
            description={node.op_intro.onecol_description}
            blueTitle={node.op_intro.onecol_bluetitle}
            blueLink={node.op_intro.onecol_bluelink}
          />
          <Craftsmanship
            title={node.op_craftsmanship.craft_title}
            description={node.op_craftsmanship.craft_description}
          />
          <Promises />
          <Outropiggy outro={node.op_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query OurPromise {
    allStrapiOurPromise {
      edges {
        node {
          id # id of the node
          op_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          op_intro {
            id
            onecol_title
            onecol_description
            onecol_bluetitle
            onecol_bluelink
          }
          op_craftsmanship {
            id
            craft_title
            craft_description
          }
          op_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
